@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

#root{
  height: 100%;
  min-height: 100%;
  font-family: 'Quicksand', sans-serif;
  font-size: 17px
}
.body{
  height: 100%;
}

/* Main Audicca Theme */
.main-color-1{ background-color: #193656 !important; color:#fff }
.main-color-2{ background-color: #2F69A3; }
.main-color-3{ background-color: #4292E3; }
.main-color-4{ background-color: #1D4063; color:#fff }
.main-color-5{ background-color: #12273D; color:#fff }

/* Theme color lighten */
.main-color-1.light { background-color: #234D78; }
.main-color-2.light { background-color: #387DC2; }
.main-color-3.light { background-color: #4AA4FF; }
.main-color-4.light { background-color: #265584; }
.main-color-5.light { background-color: #1B3D5E; }

/* Theme Color Darken */
.main-color-1.dark { background-color: #102336; }
.main-color-2.dark { background-color: #265482; }
.main-color-3.dark { background-color: #387DC2; }
.main-color-4.dark { background-color: #132B42; }
.main-color-5.dark { background-color: #08121C; }

.main-color-1.btn:hover, .main-color-1.btn-large:hover,.main-color-1.btn-small:hover{
  background-color: #1D4063;
}
.main-color-1.btn:focus, .main-color-1.btn-large:focus,.main-color-1.btn-small:focus{
  background-color: #234D78;
}
@media only screen and (min-width: 10px){
  p{
    font-size: 15px
  }
  .clienteLogo{
    /* margin-top:3%; */
    min-height: 72px;
    /* max-height: 128px; */
  }
}
@media only screen and (min-width: 601px){
  .clienteLogo{
    /* margin-top:1.5%; */
    min-height: 125px;
    /* max-height: 150px; */
  }
  nav a.sidenav-trigger {
    display: none;
  }
  nav .brand-logo {
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@media only screen and (min-width: 993px){
  .clienteLogo{
    /* margin-top:2%; */
    min-height: 125px;
    /* max-height: 165px; */
  }
}
.h-100{
  height: 100%;
}
.h-90{
  max-height: 90%;
}
.h-100.valign-wrapper, .clienteLogo.valign-wrapper{
  align-items: center;
  justify-content: center;
}
.clienteLogo a img{
  max-height: 90%;
}
.clientes-h-fix{
  height: 167px;
  margin: 4% 0;
}
.m-0{
  margin: 0;
}
.tabs .tab a.active{
  background-color: rgba(0,0,0,0.1);
}
blockquote{
  border-left: 5px solid #265482;
}
.divider{
  margin: 1% 0;
}
.right.ml-0{
  margin-left: 5px;
}
.main-text{
  color: #193656
}

.loader{
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-main-only{
  border-color: #193656;
}

.tabs-content.carousel{
  height: auto;
}

.waves-effect.waves-audicca .waves-ripple {
   background-color: rgba(25, 54, 86, 0.65);
 }